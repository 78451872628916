
export default {
  props: {
    tooltipStyle: {
      type: String,
      default:
        'min-width: 8em; width: max-content; padding: 1em 0.6em; left: -8em;'
    },
    icon: { type: String, default: '' },
    to: { type: String, default: 'home' },
    callback: { type: Function, default: null }
  },
  data() {
    return {
      active: false
    }
  },
  methods: {
    go() {
      if (this.callback) {
        this.callback()
      } else {
        this.$router.push({ path: this.to })
      }
    }
  }
}

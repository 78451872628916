  export const state = () => {
  return {
    search: [],
    favorites: [],
    lastSalleSeen: null,
    lastSalleFromLieuxList : null
  }
}

export const mutations = {
  CLEAR_SALLE_DATA(state) {
    state.search = []
    state.favorites = []
  },
  ADD_FAVORITE_SALLE(state, salle) {
    if (state.favorites.findIndex((el) => el._id === salle._id) === -1) {
      state.favorites.push(salle)
    }
  },
  REMOVE_FAVORITE_SALLE(state, salleId) {
    state.favorites.splice(
      state.favorites.findIndex((el) => el._id === salleId),
      1
    )
  },
  REMOVE_ALL_FAVORITE_SALLES(state) {
    state.favorites = []
  },
  SET_SALLES_SEARCH(state, salles) {
    state.search = salles
  },
  SET_FAVORITE_SALLES(state, salles) {
    state.favorites = salles
  },
  SET_LAST_SEEN_SALLE(state, salle) {
    state.lastSalleSeen = salle
  },
  SET_LAST_SALLE_FROM_LIEUX_LIST(state, salle) {
    state.lastSalleFromLieuxList = salle
  }
}

export const actions = {
  async addFavoriteSalle({ commit, rootGetters }, salle) {
    if (rootGetters['user/loggedIn'])
      await this.$axios.post(process.env.NUXT_ENV_API_URL + '/member/salles', {
        salleId: salle._id
      })
    commit('ADD_FAVORITE_SALLE', salle)
  },
  async addFavoriteSalleId({ commit, rootGetters }, salleId) {
    if (rootGetters['user/loggedIn'])
      await this.$axios.post(process.env.NUXT_ENV_API_URL + '/member/salles', {
        salleId
      })
    const response = await this.$axios.get(
      process.env.NUXT_ENV_API_URL + '/salle/' + salleId
    )
    commit('ADD_FAVORITE_SALLE', response.data)
  },
  async removeFavoriteSalle({ commit, rootGetters }, salleId) {
    if (rootGetters['user/loggedIn'])
      await this.$axios.delete(
        process.env.NUXT_ENV_API_URL + '/member/salles/' + salleId
      )
    commit('REMOVE_FAVORITE_SALLE', salleId)
  },
  async removeAllFavoriteSalles({ commit, rootGetters }) {
    if (rootGetters['user/loggedIn'])
      await this.$axios.delete(
        process.env.NUXT_ENV_API_URL + '/member/salles/all'
      )
    commit('REMOVE_ALL_FAVORITE_SALLES')
  },
  setSallesSearch({ commit }, salles) {
    commit('SET_SALLES_SEARCH', salles)
  },
  setFavoriteSalles({ commit }, favorites) {
    commit('SET_FAVORITE_SALLES', favorites)
  },
  async getAPIFavoriteSalles({ dispatch, commit }) {
    commit('REMOVE_ALL_FAVORITE_SALLES')
    const { data } = await this.$axios.get(
      process.env.NUXT_ENV_API_URL + '/member/salles'
    )
    dispatch('setFavoriteSalles', data)
  },
  async setAPIFavoriteSalles({ state }) {
    if (state.favorites.length !== 0) {
      await this.$axios.post(process.env.NUXT_ENV_API_URL + '/member/salles', {
        salleId: state.favorites.map((el) => el._id)
      })
    }
  },
  clearSallesData({ commit }) {
    commit('CLEAR_SALLE_DATA')
  },
  setLastSeenSalle({ commit }, salle) {
    commit('SET_LAST_SEEN_SALLE', salle)
  },
  setLastSalleFromLieuxList({ commit }, salle) {
    commit('SET_LAST_SALLE_FROM_LIEUX_LIST', salle)
  }
}

export const getters = {
  favoriteSallesSize(state) {
    return state.favorites.length
  },
  isFavorite: (state) => (id) => {
    return !!state.favorites.find((el) => el._id === id)
  },
  lastSeenSalle(state) {
    return state.lastSalleSeen
  },
  lastSalleFromLieuxList(state) {
    return state.lastSalleFromLieuxList
  }
}

export const state = () => {
  return {
    email: null,
    token: null,
    subscribed: false,
    favorites: false
  }
}

export const mutations = {
  SET_USER_DATA(state, userData) {
    state.email = userData.email
    state.token = userData.token
  },
  CLEAR_USER_DATA(state) {
    state.email = null
    state.token = null
  },
  SET_SUBSCRIBED(state, subscribed) {
    state.subscribed = subscribed
  },
  SET_FAVORITES(state, favorites) {
    state.favorites = favorites
  }
}
export const actions = {
  setUserData({ commit }, userData) {
    commit('SET_USER_DATA', userData)
    this.$axios.setToken(userData.token)
  },
  setSubscribed({ commit }, subscribed) {
    commit('SET_SUBSCRIBED', subscribed)
  },
  setFavorites({ commit }, favorites) {
    commit('SET_FAVORITES', favorites)
  },
  logout({ commit, dispatch }) {
    commit('CLEAR_USER_DATA')
    dispatch('salles/clearSallesData', {}, { root: true })
    const notification = {
      type: 'success',
      message: 'Vous êtes bien déconnecté'
    }
    dispatch('notification/add', notification, { root: true })
  }
}

export const getters = {
  loggedIn(state) {
    return !!state.email
  }
}

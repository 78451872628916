export const state = () => ({
  loading: false
})

export const mutations = {
  SET_LOADING(state, loading) {
    state.loading = loading
  }
}

export const actions = {
  start({ commit }) {
    commit('SET_LOADING', true)
  },
  end({ commit }) {
    commit('SET_LOADING', false)
  }
}

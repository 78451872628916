
export default {
  data() {
    return {
      isHome: this.$route.name === 'home',
      heroFooter: {}
    }
  },
  async created() {
    this.heroFooter = await this.getContent()
  },
  watch: {
    $route() {
      this.isHome = this.$route.name === 'home'
    },
    async '$i18n.locale'() {
      this.heroFooter = await this.getContent()
    }
  },
  methods: {
    async getContent () {
      try{
        const heroFooter = await this.$axios.$get(
          process.env.NUXT_ENV_API_URL + '/contenu',
          {
            params: {
              name: this.$i18n.locale === 'en' ? 'heroFooter_en' : 'heroFooter_fr'
            }
          }
        )
        return heroFooter
      } catch (e) {
        console.error(e)
      }
    }
  }
}


import { mapGetters } from 'vuex'
import IconLink from '@/components/IconLink.vue'

export default {
  components: {
    IconLink
  },
  data() {
    return {
      isSnapped: true,
      openNav: false,
      deconnectionModalOpened: false
    }
  },
  computed: {
    ...mapGetters({
      loggedIn: 'user/loggedIn',
      favoriteSallesSize: 'salles/favoriteSallesSize'
    })
  },
  watch: {
    $route(to) {
      // Close navbar
      this.openNav = false

      if (to.name === 'home') {
        this.isSnapped = false
      } else {
        this.isSnapped = true
      }
    }
  },
  mounted() {
    this.isSnapped = !(this.$route.name === 'home')
  },
  methods: {
    removeNav() {
      this.openNav = false
    },
    openDeconnectionModal() {
      this.deconnectionModalOpened = true
    },
    closeDeconnectionModal() {
      this.deconnectionModalOpened = false
    },
    disconnect() {
      this.deconnectionModalOpened = false
      this.$router.push(this.localePath('logout'))
    }
  }
}

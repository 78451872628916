
import Footer from '@/components/Footer.vue'
import Header from '@/components/Header.vue'
import NotificationContainer from '@/components/NotificationContainer.vue'
// import Loader from '@/components/Loader.vue'

export default {
  components: {
    'custom-footer': Footer,
    'custom-header': Header,
    NotificationContainer,
    // Loader
  },
  head() {
    return {
      title: 'Location de showroom d’exception pour la Fashion Week'
    }
  },
  mounted() {
    // disable right-click to limit the copy/paste of pictures
    window.addEventListener('contextmenu', event => {
      if(event.target.nodeName === 'IMG' || event.target.tagName === 'IMG'){
        event.preventDefault()
      }
    });
  }
}
